import React from 'react';
import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import { TImage } from '..';
import {
  BannerSpecialButton,
  BannerSpecialOfferBody,
  BannerSpecialOfferContainer,
  BannerSpecialOfferContent,
  BannerSpecialOfferContentWrapper,
  BannerSpecialOfferImageWrapper,
  BannerSpecialText,
  BannerSpecialTitleText,
} from './styles';

type TBannerSpecialOfferProps = {
  title?: string;
  description?: IRichTextData;
  subTitle?: string;
  image?: TImage;
  richTextCustom?: boolean;
  overlayImage?: TImage;
  handleSegmentEvent?: (text?: string) => void;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

const BannerSpecialOffer = ({
  title,
  description,
  image,
  richTextCustom,
  ctaCollection,
  subTitle,
  host,
  handleSegmentEvent,
  faultToleranceWarn,
}: TBannerSpecialOfferProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host as string);
  const newUrl = getPathMenuFiltersLink(ctaUrl);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent?.(SegmentEvents.BANNER_VIEWED);
  });
  if (
    (!subTitle ||
      !title ||
      !description ||
      !ctaCollection?.title ||
      !image?.mobileImage?.url ||
      !image?.desktopImage?.url) &&
    faultToleranceWarn
  )
    faultToleranceWarn('BannerSpecialOffer', title);
  return (
    <BannerSpecialOfferContainer ref={targetRef} $richTextCustom={richTextCustom} data-automationid="c11_banner_b_00">
      <BannerSpecialOfferBody>
        <BannerSpecialOfferContentWrapper>
          {subTitle && <BannerSpecialText variant="body-small">{subTitle}</BannerSpecialText>}
          <BannerSpecialOfferContent>
            {title && <BannerSpecialTitleText variant="h2">{title}</BannerSpecialTitleText>}
            {description && (
              <ContentfulRichText
                data={description}
                renderNodeOptions={{
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <BannerSpecialTitleText variant="body-large">{children}</BannerSpecialTitleText>
                  ),
                }}
              />
            )}
          </BannerSpecialOfferContent>
          {ctaCollection?.title && (
            <BannerSpecialButton
              button-type="primary"
              href={newUrl}
              target={getLinkTarget(newUrl)}
              as="a"
              size="lg"
              color="green"
              onClick={() => handleSegmentEvent?.(SegmentEvents.BANNER_CLICKED)}
            >
              {ctaCollection?.title}
            </BannerSpecialButton>
          )}
        </BannerSpecialOfferContentWrapper>
        {(image?.mobileImage?.url || image?.desktopImage?.url) && (
          <BannerSpecialOfferImageWrapper>
            <Image
              src={!isTablet && image?.mobileImage ? image?.mobileImage?.url || '' : image?.desktopImage?.url || ''}
              alt={!isTablet && image?.mobileImage ? image?.mobileImage?.title || '' : image?.desktopImage?.title || ''}
              loader={(options) => options?.src}
              width={isMediumDesktop ? 1013 : isTablet ? 725 : 727}
              height={isMediumDesktop ? 540 : isTablet ? 388 : 523}
            />
          </BannerSpecialOfferImageWrapper>
        )}
      </BannerSpecialOfferBody>
    </BannerSpecialOfferContainer>
  );
};

export default BannerSpecialOffer;

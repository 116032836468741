import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ProductCardVertical from 'components/molecules/cards/product-card/product-card-vertical';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { ICartItem, IContentfulImage, IRichTextData } from 'helpers/types';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import {
  BannerProductCardContainer,
  BannerProductContainer,
  BannerProductImageContianer,
  BannerProductInformationContainer,
  BannerProductItemContainer,
  BannerProductTitleStyles,
  BannerProductWrapper,
  CommonButtonStyles,
  Spacer,
} from './styles';

export type BgColorProps = 'kief' | 'grey';
export type VariantProps = 'card' | 'image';

type ProductProps = {
  showFavorite?: boolean;
  containersStyle?: string;
  image: string;
  productId?: number;
  name: string;
  brandName: string;
  price?: number;
  order?: number;
  discountPrice?: number;
  type?: string;
  thc?: string;
  kind?: string;
  gram?: string;
  saleLabel?: string;
  newLabel?: string;
  strainsLabel?: string;
  averageRating?: number;
  reviews?: number;
  defaultWeightSelect?: string;
  handleSelectedWeight?: (data: any) => void;
  handleSegmentEvent?: (text?: string) => void;
  isShowWeightSelector?: boolean;
  buttonText?: string;
  position?: number;
  url?: string;
  maxCartLimit?: number;
  filterReviewsLocations?: boolean;
  weights?: TProductSize[];
  existingProducts?: ICartItem[];
  totalQuantity?: number;
  isOH?: boolean;
  trackViewEvent?: boolean;
};

type BannerProductProps = {
  title: string;
  subTitle: string;
  bgColor: BgColorProps;
  image: string;
  description?: IRichTextData;
  variant: VariantProps;
  productProps?: ProductProps;
  handleSegmentEvent?: (type: string) => void;
  productImageProps?: {
    additionalImage?: string;
    overlayImage: IContentfulImage;
    ctaText: string;
    ctaLink: string;
  };
  host?: string;
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

export const BannerProduct = ({
  title,
  subTitle,
  bgColor,
  description,
  image,
  productProps,
  variant,
  productImageProps,
  handleSegmentEvent,
  host,
  faultToleranceWarn,
}: BannerProductProps) => {
  const containerStyle = bgColor === 'kief' && variant === 'image' ? { backgroundImage: `url(${image})` } : null;
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });
  if ((!subTitle || !title) && faultToleranceWarn) faultToleranceWarn('BannerProduct', title);
  return (
    <BannerProductWrapper ref={targetRef} $variant={variant}>
      <BannerProductContainer $variant={variant} style={containerStyle}>
        <BannerProductInformationContainer $variant={variant}>
          {subTitle && (
            <Typography variant="body-small" className={BannerProductTitleStyles} data-testid="Product-banner-title">
              {subTitle}
            </Typography>
          )}
          {title && (
            <Typography
              variant="h3"
              className={`${variant === 'image' ? '' : 'lg:pb-[60px] 2xl:pb-0'}`}
              data-testid="Product-banner-subTitle"
            >
              {title}
            </Typography>
          )}
          {variant != 'card' && (
            <ContentfulRichText
              data={description}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant="body-large">{children}</Typography>,
              }}
            />
          )}
          {variant === 'image' && (
            <CommonButton
              as="a"
              className={CommonButtonStyles}
              color="green"
              button-type="primary"
              href={productImageProps?.ctaLink}
              host={host}
              onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
            >
              {productImageProps?.ctaText}
            </CommonButton>
          )}
        </BannerProductInformationContainer>
        <div className="lg:flex">
          <Spacer $variant={variant} />
          {variant === 'card' && (
            <BannerProductCardContainer data-testid="Product-card">
              <ProductCardVertical {...productProps} />
            </BannerProductCardContainer>
          )}
          {variant === 'image' && (
            <BannerProductItemContainer>
              <SimpleImageOrVideo
                imageOrVideo={productImageProps?.overlayImage}
                imageProps={{
                  width: 280,
                  height: 280,
                }}
              />
            </BannerProductItemContainer>
          )}
          <BannerProductImageContianer
            $variant={variant}
            style={{
              backgroundImage: `url(${productImageProps?.additionalImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </BannerProductContainer>
    </BannerProductWrapper>
  );
};

import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { TBannerHelpProps } from 'helpers/types/organisms/banners/banner-help';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { BannerHelpContainer, BannerHelpImage, BannerHelpInnerContainer, BannerHelpTextContainer } from './styles';

export const BannerHelpComponent = ({
  title,
  subtitle,
  imageUrl,
  handleSegmentEvent,
  host,
  cta,
  faultToleranceWarn,
}: TBannerHelpProps) => {
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });
  const newUrl = getPathMenuFiltersLink(cta?.longUrl);
  if ((!title || !subtitle || !cta?.title || !imageUrl) && faultToleranceWarn)
    faultToleranceWarn('BannerHelpComponent', title);
  return (
    <BannerHelpContainer ref={targetRef}>
      <BannerHelpInnerContainer>
        <BannerHelpTextContainer>
          {title && (
            <Typography variant={'h4'} data-testid="banner_help_title" className="mb-4 md:mb-[10px]">
              {title}
            </Typography>
          )}
          {subtitle && (
            <ContentfulRichText
              data={subtitle}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography className="!text-grey-400" variant="body-small" data-testid="banner_help_subtitle">
                    {children}
                  </Typography>
                ),
              }}
            />
          )}
        </BannerHelpTextContainer>
        {cta?.title && (
          <div className="flex">
            <CommonButton
              as="a"
              color="green"
              button-type="primary"
              size="lg"
              href={newUrl}
              target={cta.openInNewTab ? '_blank' : '_self'}
              host={host}
              onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
              data-testid="banner_help_button"
            >
              {cta.title}
            </CommonButton>
          </div>
        )}
      </BannerHelpInnerContainer>
      {imageUrl && <BannerHelpImage src={imageUrl} data-testid="banner_help_image" />}
    </BannerHelpContainer>
  );
};

import { AlgoliaProduct } from 'components/molecules/cards/product-card/types';
import { WEIGHT_OBJECT } from 'helpers/constants/product';
import { capitalizeLabel, getDefaultProductImageUrl } from 'helpers/utils/product-details';

/**
 * Determines the default selected weight for a product based on available weights and user-selected filters.
 *
 * This function prioritizes selecting the smallest weight from the user-selected filters if they are available
 * in the product's data. If no filters are applied or no matching weights are found, it falls back to a default
 * weight, typically 3.5g (eighth ounce).
 *
 * @function getDefaultWeightSelect
 *
 * @param {object} productData - The data for the current product, including available weights and pricing.
 * @param {array} variant - An array of product variants, used as a fallback if no weight filters are applied.
 *
 * @returns {string} - The selected weight, either from the filters or the default (3.5g or another available weight).
 *
 */
export const getDefaultWeightSelect = (productData, variant) => {
  // Get selected weight filters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const selectedWeights = urlParams
    .getAll('refinementList[available_weights][]')
    .map((weight) => weight.replace(' ', '_'));

  // Define available weights in the correct order
  const availableWeights = ['half_gram', 'gram', 'two_gram', 'eighth_ounce', 'quarter_ounce', 'half_ounce', 'ounce'];

  // Determine the default weight based on available filters and product data
  if (selectedWeights.length > 0) {
    for (const weight of availableWeights) {
      if (selectedWeights.includes(weight) && productData?.available_weights?.includes(weight.replace('_', ' '))) {
        return weight;
      }
    }
  }

  // Fallback to 3.5g (eighth_ounce) if no filters or smaller weights are available
  return productData?.price_eighth_ounce ? 'eighth_ounce' : variant?.[0]?.name;
};

/**
 * Formats THC-related product tags based on the product data, including potencies (THC, THCa, CBD)
 * for applicable product types and dosage/amount for other types.
 *
 * @param {object} productData - The product data containing information such as kind, potency, dosage, and inventory details.
 * @param {string} productData.kind - The type of product (e.g., 'vape', 'flower', 'pre-roll', 'extract', 'edible', etc.).
 * @param {Array} [productData.inventory_potencies] - Optional array of inventory potencies containing THC, THCa, and CBD percentages.
 * @param {number} [productData.percent_thca] - Percentage of THCa for the product.
 * @param {number} [productData.percent_thc] - Percentage of THC for the product.
 * @param {number} [productData.percent_cbd] - Percentage of CBD for the product.
 * @param {number} [productData.dosage] - Dosage information for applicable product types (e.g., edibles, tinctures).
 * @param {number} [productData.amount] - Amount information for applicable product types (e.g., topicals).
 * @returns {string} - A formatted string containing relevant product potency (THC, THCa, CBD) or dosage/amount information.
 */
export const formatThc = (productData: any): string => {
  if (!productData) return ''; // Early return if no product data

  const { kind, inventory_potencies = [], percent_thca, percent_thc, percent_cbd, dosage, amount } = productData;

  // Destructure potencies from first inventory_potencies item or default to an empty object
  const { tac_potency, thc_potency, cbd_potency } = inventory_potencies[0] || {};

  // Utility to handle optional potency formatting
  const formatPotency = (label: string, value?: number): string => (value ? `${label} ${value}%` : '');

  // Switch based on product kind
  switch (kind) {
    case 'vape':
    case 'flower':
    case 'pre-roll':
    case 'extract':
      // Filter out empty values and join them with a space
      return [
        formatPotency('THCa', tac_potency || percent_thca),
        formatPotency('THC', thc_potency || percent_thc),
        formatPotency('CBD', cbd_potency || percent_cbd),
      ]
        .filter(Boolean)
        .join(' ');

    case 'edible':
    case 'tincture':
    case 'topical':
      // Return dosage and amount, filtered and joined by a space
      return [dosage, amount].filter(Boolean).join(' ');

    default:
      return ''; // Return empty string for unsupported kinds
  }
};

/**
 * Retrieves the specific variant data for a given weight from the product and sets price fields if missing.
 *
 * @param {string} weightName - The name of the weight variant (e.g., 'eighth_ounce' or 'each') to retrieve.
 * @param {AlgoliaProduct} product - The product object containing variant and variant details.
 *
 * @returns {any} - The specific variant's data with `price`, `discounted_price`, and filtered `inventory_potencies` fields,
 * or an empty object if no matching variant is found.
 */
export const getDataByWeight = (weightName: string, product: any): any => {
  const formattedWeightName = weightName?.replace(/_/g, ' ') || 'each';

  // Retrieve variant data
  const productId = product?.variants?.[formattedWeightName]?.product_id;
  const originalProductData = product?.variants_details?.[productId] || {};

  // Create a shallow copy of productData to ensure it's mutable
  const productData = { ...originalProductData };

  // Set price fields if they are present in variantData
  const setPriceField = (sourceField: string, targetField: string) => {
    if (sourceField in productData) {
      const parsedValue = parseFloat(productData[sourceField]);
      productData[targetField] = !isNaN(parsedValue) ? parsedValue : null;
    }
  };

  // Map fields based on the selected weight
  setPriceField(`price_${weightName}`, 'price');
  setPriceField(`discounted_price_${weightName}`, 'discounted_price');
  setPriceField(`special_price_${weightName}`, 'special_price');
  setPriceField(`max_cart_quantity_${weightName}`, 'max_cart_quantity');

  // Filter inventory_potencies on productData based on formattedWeightName (matching price_id)
  const inventoryPotencies =
    productData.inventory_potencies?.filter((potency) => potency.price_id === weightName) || [];

  if (inventoryPotencies.length) {
    productData.inventory_potencies = inventoryPotencies;
  }

  return productData;
};

export const getProductCardStrainsLabelVariant = (
  strainsLabel: string,
): 'orange-border' | 'blue-border' | 'purple-border' | 'white-border' => {
  const label = strainsLabel?.toLowerCase() || '';
  const labelVariants = {
    sativa: 'orange-border',
    indica: 'blue-border',
    hybrid: 'purple-border',
  };

  return labelVariants[label] || 'white-border';
};

/**
 * Formats product variants into a structured array.
 * Includes functionality to format weight and capitalize labels within the same function.
 *
 * @param {Object} variants - The variants object with keys representing variant names and values containing variant data.
 * @returns {Array<Object>} An array of formatted variant objects, each containing:
 *          - label: Capitalized label or variant amount.
 *          - name: Weight formatted with underscores instead of spaces.
 *          - value: Numeric product ID associated with the variant.
 */
export const formatVariants = (variants = {}) => {
  // Ensure that the variants object has valid entries
  if (!variants || typeof variants !== 'object' || Object.keys(variants).length === 0) {
    return [];
  }

  return Object.entries(variants).map(([name, { amount = '', product_id = null } = {}]) => {
    const variant = name !== 'null' ? name : 'each';

    // Inline function to capitalize the label
    const capitalizeLabel = (label) => {
      if (!label) return null;
      if (label.toLowerCase() === 'cbd') return 'CBD';
      return label
        .split('-')
        .map((word) => word[0]?.toUpperCase() + word.slice(1))
        .join(' ');
    };

    // Inline function to format the weight
    const formatWeight = (label) => label?.toLowerCase()?.replace(/\s+/g, '_');

    return {
      label: amount || capitalizeLabel(WEIGHT_OBJECT?.[variant] || variant),
      name: formatWeight(variant),
      value: product_id ? Number(product_id) : null,
    };
  });
};

export const formatProductCard = ({ variants, variants_details }: AlgoliaProduct) => {
  if (!variants || !variants_details) return {};

  const formattedVariants = formatVariants(variants);

  const productId = formattedVariants?.[0]?.value;
  const data = productId && variants_details[productId];

  if (!productId || !data) return {};

  return {
    name: data?.name,
    kind: data?.kind,
    type: capitalizeLabel(data?.brand_subtype),
    productId: productId,
    image:
      data?.image_urls?.[0] ||
      getDefaultProductImageUrl(data?.kind === 'flower' ? data.kind : data?.type, data?.category, data?.root_subtype),
    slug: data?.url_slug,
    brandName: data?.brand,
    thc: formatThc(data),
    saleLabel: data?.special_amount || '',
    strainsLabel: capitalizeLabel(data?.category) || '',
    reviews: data?.review_count,
    averageRating: data?.aggregate_rating,
    weights: formattedVariants || [],
    buttonText: formattedVariants?.length > 1 ? 'Select weight' : 'Add to cart',
    isShowWeightSelector: data?.available_weights?.length > 0 || false,
  };
};

/**
 * Formats an array of product cards by separating actual products and placeholders.
 *
 * This function filters the input array into two categories:
 * 1. Actual products, which are objects that have at least one key.
 * 2. Placeholders, which are empty objects with no keys.
 *
 * The function then returns a new array that has all the actual products
 * followed by all the placeholders, preserving the order within each category.
 *
 * @template T - The type of the items in the input array.
 * @param {T[]} array - An array of product card objects to be formatted.
 * @returns {T[]} A new array with all actual products followed by placeholders.
 *
 * @example
 * const products = [
 *   { id: 1, name: "Product 1" },
 *   {}, // Placeholder
 *   { id: 2, name: "Product 2" },
 *   {}  // Placeholder
 * ];
 * const result = formatProductCards(products);
 * // result: [{ id: 1, name: "Product 1" }, { id: 2, name: "Product 2" }, {}, {}]
 */
export const formatProductCards = (array) => {
  const actualProducts = array.filter((item) => Object.keys(item).length > 0);
  const placeholders = array.filter((item) => Object.keys(item).length === 0);

  return [...actualProducts, ...placeholders];
};

/**
 * Counts the number of placeholder objects in an array.
 *
 * A placeholder object is defined as an object with no keys.
 * This function filters the array to identify such objects and returns
 * the total count of placeholders found.
 *
 * @template T - The type of the items in the input array.
 * @param {T[]} array - An array of objects to count placeholders from.
 * @returns {number} The number of placeholder objects in the array.
 *
 * @example
 * const items = [
 *   { id: 1, name: "Item 1" },
 *   {}, // Placeholder
 *   { id: 2, name: "Item 2" },
 *   {}  // Placeholder
 * ];
 * const result = countPlaceholders(items);
 * // result: 2
 */
export const countPlaceholders = <T extends Record<string, any>>(array: T[]): number => {
  return array.filter((item) => Object.keys(item).length === 0).length;
};

/**
 * Generates a URL with query parameters based on the provided inputs.
 *
 * @param {number} page - The current page number to include in the URL.
 * @param {QueryParams} queryParams - An object containing query parameters, which can be strings or arrays of strings.
 * @param {string} card_list_type - The type of card list, used to determine URL structure.
 * @param {string} storeId - The store ID to include as a query parameter.
 * @param {string} stateId - The state ID used to build the state_slug parameter.
 * @param {string} paginationQuery - A string containing additional pagination query parameters.
 * @returns {string} - The constructed URL with all the necessary query parameters.
 */
type QueryParams = {
  [key: string]: string | string[];
};
export const generateUrl = (
  page: number,
  queryParams: QueryParams,
  card_list_type: string,
  storeId: string,
  stateId: string,
  paginationQuery: string,
): string => {
  if (card_list_type === 'search-grid') {
    const urlParts: string[] = [];

    if (typeof queryParams.query === 'string') {
      urlParts.push(`query=${encodeURIComponent(queryParams.query)}`);
    }
    if (typeof queryParams.category === 'string') {
      urlParts.push(`category=${encodeURIComponent(queryParams.category)}`);
    }

    urlParts.push(`page=${page}`);

    Object.entries(queryParams).forEach(([key, value]) => {
      if (key !== 'query' && key !== 'category') {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            urlParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`);
          });
        } else if (typeof value === 'string') {
          urlParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
      }
    });

    urlParts.push(`store_id=${storeId}`);
    urlParts.push(`state_slug=/dispensaries/${stateId}`);

    return `?${urlParts.join('&')}`;
  } else {
    return `?${paginationQuery}&page=${page}&store_id=${storeId}&state_slug=${stateId}`;
  }
};

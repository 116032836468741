import React, { useState } from 'react';
import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, IRichTextData, VariantType } from 'helpers/types/common.type';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { mediumDesktop, smallMobile, tablet } from 'helpers/utils/screensizes';
import { useAppSelector } from 'redux/hooks';
import { TImage } from '..';
import RewardVideo from '../../banner-video';
import {
  BIRewardsBody,
  BIRewardsDetailContainer,
  BIRewardsImage,
  BIRewardsOverlayImg,
  BannerImgContainer,
  BannerImgHeighAspectRatio,
  BannerRewardButton,
  BannerRewardDescriptionText,
  BannerRewardTitleText,
  BannerRewardsContainer,
} from './styles';

type TBannerRewardsProps = {
  title: string;
  description?: IRichTextData;
  image: TImage;
  overlayImage?: TImage;
  additionalImage?: TImage;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
  video?: any;
  variant?: VariantType;
  handleSegmentEvent?: (type?: string) => void;
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

const BannerRewards = ({
  title,
  description,
  image,
  overlayImage,
  additionalImage,
  ctaCollection,
  host,
  handleSegmentEvent,
  video,
  variant = VariantType.REWARDS,
  faultToleranceWarn,
}: TBannerRewardsProps) => {
  const [open, setOpen] = useState(false);
  const [isTablet] = useMediaQuery(tablet);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  const [isMobile] = useMediaQuery(smallMobile);
  const cookiePermissions = useAppSelector((state) => state?.cookiesReducer?.cookieGroups?.includes('C0004:1'));
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host as string);
  const newUrl = getPathMenuFiltersLink(ctaUrl);

  const openModal = () => setOpen(true);
  const handleOpen = () => setOpen(!open);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent?.(SegmentEvents.BANNER_VIEWED);
  });

  const src = (isMobile ? image.desktopImage?.url : image.mobileImage?.url || image.desktopImage?.url) || '';
  const alt = (isMobile ? image.desktopImage?.title : image.mobileImage?.title || image.desktopImage?.title) || '';
  if (
    (!image?.mobileImage?.url ||
      !image?.desktopImage?.url ||
      !overlayImage?.desktopImage ||
      !overlayImage?.mobileImage ||
      !title ||
      !description ||
      !ctaCollection.title) &&
    faultToleranceWarn
  )
    faultToleranceWarn('BannerRewards', title);
  return (
    <BannerRewardsContainer ref={targetRef} data-automationid="c11_banner_c_00">
      {(image?.mobileImage?.url || image?.desktopImage?.url) && (
        <BIRewardsImage>
          <BannerImgContainer>
            <BannerImgHeighAspectRatio src={src} />
            <Image
              src={src}
              layout={'fill'}
              objectFit="contain"
              title={alt}
              alt={alt}
              loader={({ src }) => src}
              aria-label={alt}
              priority
            />
          </BannerImgContainer>
        </BIRewardsImage>
      )}
      <BIRewardsDetailContainer
        style={{
          backgroundImage: `${
            additionalImage?.mobileImage?.url || additionalImage?.desktopImage?.url
              ? `url(${
                  isTablet
                    ? additionalImage?.desktopImage?.url
                    : additionalImage?.mobileImage?.url || additionalImage?.desktopImage?.url
                }`
              : 'unset'
          }`,
        }}
      >
        {variant !== VariantType.TEXT_AREA && variant !== VariantType.VIDEO ? (
          <>
            {(overlayImage?.desktopImage || overlayImage?.mobileImage) && (
              <BIRewardsOverlayImg>
                <ResponsiveImageOrVideo
                  desktop={overlayImage?.desktopImage}
                  mobile={overlayImage?.mobileImage as IContentfulImage}
                  imageProps={{
                    imageHeight: isMediumDesktop ? 272 : isTablet ? 195 : 172,
                    imageWidth: isMediumDesktop ? 278 : isTablet ? 200 : 190,
                    alt: overlayImage?.desktopImage?.title || overlayImage?.mobileImage?.title || '',
                  }}
                />
              </BIRewardsOverlayImg>
            )}
          </>
        ) : null}
        <BIRewardsBody>
          {title && <BannerRewardTitleText variant="h3">{title}</BannerRewardTitleText>}
          {description && (
            <ContentfulRichText
              data={description}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <BannerRewardDescriptionText variant="body-small">{children}</BannerRewardDescriptionText>
                ),
              }}
            />
          )}
          {variant === VariantType.VIDEO ? (
            <>
              {cookiePermissions && (
                <>
                  {ctaCollection.title && (
                    <BannerRewardButton size="sm" button-type="primary" color="white" onClick={openModal}>
                      {ctaCollection.title}
                    </BannerRewardButton>
                  )}
                  <RewardVideo videoData={video} isOpen={open} onClick={handleOpen} />
                </>
              )}
            </>
          ) : (
            <>
              {ctaCollection.title && (
                <BannerRewardButton
                  as="a"
                  href={newUrl}
                  target={getLinkTarget(newUrl)}
                  size="sm"
                  button-type="primary"
                  color="white"
                  onClick={() => handleSegmentEvent?.(SegmentEvents.BANNER_CLICKED)}
                >
                  {ctaCollection.title}
                </BannerRewardButton>
              )}
            </>
          )}
        </BIRewardsBody>
      </BIRewardsDetailContainer>
    </BannerRewardsContainer>
  );
};

export default BannerRewards;

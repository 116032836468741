import React from 'react';
import Image from 'next/image';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { TImage } from '..';
import {
  BannerDealsBody,
  BannerDealsButton,
  BannerDealsContainer,
  BannerDealsContent,
  BannerDealsContentWrapper,
  BannerDealsImage,
  BannerDealsImageWrapper,
  BannerDealsImg,
  BannerDealsSubTitleText,
  BannerDealsTitleText,
} from './styles';

type TBannerDealsProps = {
  title: string;
  subTitle?: string;
  image: TImage;
  overlayImage?: TImage;
  handleSegmentEvent?: (type?: string) => void;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

const BannerDeals = ({
  title,
  subTitle,
  image,
  ctaCollection,
  overlayImage,
  handleSegmentEvent,
  host,
  faultToleranceWarn,
}: TBannerDealsProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host as string);
  const newUrl = getPathMenuFiltersLink(ctaUrl);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent?.(SegmentEvents.BANNER_VIEWED);
  });
  if (
    (!image?.mobileImage?.url ||
      !image?.desktopImage?.url ||
      !subTitle ||
      !title ||
      !ctaCollection?.title ||
      !overlayImage?.mobileImage?.url ||
      !overlayImage?.desktopImage?.url) &&
    faultToleranceWarn
  )
    faultToleranceWarn('BannerDeals', title);
  return (
    <BannerDealsContainer ref={targetRef} data-automationid="c10_banner_a_00">
      {(image?.mobileImage?.url || image?.desktopImage?.url) && (
        <BannerDealsImage>
          <BannerDealsImg
            src={!isTablet && image?.mobileImage ? image?.mobileImage?.url || '' : image?.desktopImage?.url || ''}
            loader={(options) => options.src}
            alt={!isTablet && image?.mobileImage ? image?.mobileImage?.title || '' : image?.desktopImage?.title || ''}
            layout="fill"
            objectFit="cover"
          />
        </BannerDealsImage>
      )}
      <BannerDealsBody>
        <BannerDealsContentWrapper>
          <BannerDealsContent>
            {subTitle && (
              <BannerDealsSubTitleText color="resin" variant="body-small">
                {subTitle}
              </BannerDealsSubTitleText>
            )}
            {title && <BannerDealsTitleText variant="h3">{title}</BannerDealsTitleText>}
            {ctaCollection?.title && (
              <BannerDealsButton
                button-type="tertiary"
                color="green"
                size="lg"
                className="!p-0 lg:!p-0"
                icon={<ChevronRightIcon />}
                iconPosition="after"
                as="a"
                target={getLinkTarget(newUrl)}
                href={newUrl}
                onClick={() => handleSegmentEvent?.(SegmentEvents.BANNER_CLICKED)}
              >
                {ctaCollection?.title}
              </BannerDealsButton>
            )}
          </BannerDealsContent>
          {(overlayImage?.mobileImage?.url || overlayImage?.desktopImage?.url) && (
            <BannerDealsImageWrapper>
              <Image
                src={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.url || ''
                    : overlayImage?.desktopImage?.url || ''
                }
                loader={(options) => options?.src}
                alt={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.title || ''
                    : overlayImage?.desktopImage?.title || ''
                }
                width={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.width
                    : overlayImage?.desktopImage?.width
                }
                height={
                  !isTablet && overlayImage?.mobileImage
                    ? overlayImage?.mobileImage?.height
                    : overlayImage?.desktopImage?.height
                }
              />
            </BannerDealsImageWrapper>
          )}
        </BannerDealsContentWrapper>
      </BannerDealsBody>
    </BannerDealsContainer>
  );
};

export default BannerDeals;

export const getItemPropertyValue = (item, property, isTablet) => {
  const image = isTablet ? item.desktopImage : item.mobileImage || item.desktopImage;
  return image ? image[property] : item[property];
};

export const getItemsInView = (gridRef: any, itemsPerPage: number) => {
  const items = gridRef?.current?._scrollingContainer?.firstChild?.children;
  const itemsLength = items?.length || 0;
  if (itemsLength) {
    const isInsideViewport = (el) => {
      const rectangle = el?.getBoundingClientRect();
      const windowWidth = window?.innerWidth || document?.documentElement?.clientWidth;
      const insideX = rectangle?.left >= 0 && rectangle?.left + rectangle?.width <= windowWidth;
      return insideX;
    };
    const lastItem = items?.[itemsLength - 1].firstChild;
    const isLastItemInview = isInsideViewport(lastItem);
    const displayItemInview = itemsLength + (isLastItemInview ? 0 : -1);
    if (itemsPerPage !== displayItemInview && !isNaN(displayItemInview) && displayItemInview > 0) {
      return displayItemInview;
    }
  }
};

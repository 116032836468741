import React from 'react';
import Image from 'next/image';
import { Dialog, DialogBody } from '@material-tailwind/react/components/Dialog';
import { CenteredCarousel } from 'components/molecules/carousels/centered-carousel';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import { getItemPropertyValue } from 'helpers/utils/grid-image-carousal';
import { tablet } from 'helpers/utils/screensizes';
import CloseIcon from 'public/icons/figma/close-small.svg';
import { ImageGridContainer } from './styles';

type TProps = {
  image: TContenfulImageProp[];
};

const GridImage = ({ image }: TProps) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isTablet] = useMediaQuery(tablet);
  const closeDrawer = () => setOpenDrawer(false);
  return (
    <React.Fragment>
      <div className="grid px-5 md:place-content-center md:px-0" data-testid="grid-image">
        <ImageGridContainer>
          {image &&
            image?.map((item, index) => (
              <button
                onClick={() => setOpenDrawer(true)}
                key={index}
                className="relative h-[168px] w-full  md:h-[240px] md:w-[240px] xl:h-[381px] xl:w-[381px]"
              >
                <Image
                  alt={getItemPropertyValue(item, 'alt', isTablet)}
                  loader={(options) => options.src}
                  src={getItemPropertyValue(item, 'url', isTablet)}
                  title={getItemPropertyValue(item, 'title', isTablet)}
                  aria-labelledby={getItemPropertyValue(item, 'title', isTablet)}
                  objectFit="cover"
                  layout="fill"
                  className="h-full w-full rounded-md object-cover"
                />
              </button>
            ))}
        </ImageGridContainer>
      </div>
      {openDrawer ? (
        <Dialog open={openDrawer} handler={closeDrawer} className={'bg-transparent shadow-transparent'}>
          <DialogBody>
            <div className="flex w-full flex-col items-end">
              <button onClick={closeDrawer}>
                <CloseIcon size="2em" color="white" />
              </button>
            </div>
            <CenteredCarousel
              paginationColor="leaf"
              containerHeight={`${Math.floor(
                (608 * image?.[0]?.desktopImage?.height) / image?.[0]?.desktopImage?.width,
              )}px`}
              mobilePaginationVariant="pill-mobile"
              images={image}
              textColor="white-color-200"
              isArticleCarousel={true}
            />
          </DialogBody>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
};

export default GridImage;

import React from 'react';
import { IContentfulImage, IRichTextData, VariantType } from 'helpers/types';
import BannerDeals from './banner-deals';
import BannerRewards from './banner-rewards';
import BannerSpecialOffer from './banner-special-offer';

export type TImage = {
  mobileImage?: IContentfulImage;
  desktopImage: IContentfulImage;
};

type TProps = {
  variant: VariantType;
  title: string;
  description?: IRichTextData;
  subTitle?: string;
  image: TImage;
  overlayImage?: TImage;
  richTextCustom?: boolean;
  additionalImage?: TImage;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
  bannerType?: string;
  video?: any;
  handleSegmentEvent?: (type?: string) => void;
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

const BannerInformation = (props: TProps) => {
  switch (props.variant) {
    case VariantType.TEXT_AREA:
      return <BannerRewards {...props} />;
    case VariantType.VIDEO:
      return <BannerRewards {...props} />;
    case VariantType.REWARDS:
      return <BannerRewards {...props} />;
    case VariantType.SPECIAL_OFFER:
      return <BannerSpecialOffer richTextCustom {...props} />;
    case VariantType.DEALS:
      return <BannerDeals {...props} />;
  }
};

export default BannerInformation;

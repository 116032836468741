import React from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import QuantitySelector from 'components/atoms/buttons/quantity-selector';
import { WeightSelectorButton } from 'components/atoms/buttons/weight-selector';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { SegementAddToCartTriggerSource } from 'helpers/analytics/cart-product-added';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { useCartState } from 'helpers/utils/cart-funationaltiy';
import { getProductCardStrainsLabelVariant } from 'helpers/utils/product-card';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ProductCardTypography from '../../product-card-typography';

type TProps = {
  containersStyle?: string;
  image: string;
  name: string;
  brandName: string;
  price: number;
  strainsLabel?: string;
  onClose: () => void;
  weights?: TProductSize[];
  productId?: number;
  defaultWeightSelect: string;
  handleSelectedWeight?: (item: any) => void;
  productSizes?: Array<TProductSize>;
  isShowWeightSelector?: boolean;
  maxCartLimit?: number;
  triggeredFrom?: string;
  thc?: string;
  offer: string;
  kind?: string;
  order?: number;
  position?: number;
  discountPrice?: string;
  storeId?: string;
};

const ProductCardConfirmVertical: React.FC<TProps> = ({
  containersStyle = '',
  image,
  productId,
  order,
  name,
  kind,
  brandName,
  price,
  position,
  thc = '',
  defaultWeightSelect,
  onClose,
  strainsLabel,
  offer,
  weights,
  handleSelectedWeight,
  isShowWeightSelector = true,
  maxCartLimit = 10,
  discountPrice = '',
  storeId,
}) => {
  const tags = thc.split(',').map((tag) => tag.trim());
  const store_id = parseInt(storeId);
  const discount_price = parseFloat(discountPrice);
  const cartProps = {
    tags,
    position,
    defaultWeightSelect,
    productId,
    placement: order,
    storeId: store_id,
    brand: brandName,
    strain: name,
    originalPrice: price,
    price: discount_price,
    offerText: offer,
    kind,
  };
  const triggeredFrom = SegementAddToCartTriggerSource.NON_PDP;
  const {
    selectedQuantity,
    selectedWeight,
    setSelectedWeight,
    isSelectedWeightInCart,
    setSelectedQuantity,
    handleCartClick,
    existingItem,
  } = useCartState(cartProps, triggeredFrom, onClose);
  const totalPrice = price * selectedQuantity;
  const buttonLabel =
    !existingItem.quantity || (isSelectedWeightInCart && existingItem.quantity === selectedQuantity)
      ? `Confirm $${totalPrice.toFixed(2)}`
      : selectedQuantity === 0
      ? 'Remove Item'
      : `Update Cart $${totalPrice.toFixed(2)}`;

  const handleWeightChange = (item: TProductSize) => {
    handleSelectedWeight?.(item);
    if (item?.name !== selectedWeight) {
      setSelectedWeight(item?.name);
      setSelectedQuantity(1);
    }
  };

  return (
    <div
      className={`relative flex h-[440px] w-full max-w-[260px] flex-col justify-between rounded-md border border-solid border-grey-300 bg-white md:h-[594px] md:min-w-[230px] md:max-w-[400px] xl:h-[666px] xl:min-w-[230px] xl:max-w-[432px] ${containersStyle}`}
    >
      <div className="p-4 xl:p-8">
        <div className="flex flex-row justify-between">
          <div className="col-span-2 flex flex-col justify-between">
            <div className="flex">
              <IconButton onClick={onClose} button-type="primary" className="!bg-grey-200" color="white">
                <ChevronLeftIcon />
              </IconButton>
            </div>
            {strainsLabel && (
              <div className="flex">
                <Label variant={getProductCardStrainsLabelVariant(strainsLabel)}>{strainsLabel}</Label>
              </div>
            )}
          </div>
          <div className="relative h-[88px] w-[88px] overflow-hidden lg:h-[116px] lg:w-[116px]">
            <Image
              layout="fill"
              alt="product card"
              loader={({ src }) => src}
              src={image}
              className="h-full w-full object-cover lg:rounded-md"
              priority
            />
          </div>
        </div>
        <div className="border-b border-solid border-grey-200 pb-4 pt-3 lg:pb-5 lg:pt-4">
          <ProductCardTypography variant="brand" text={brandName} />
          <ProductCardTypography variant="name" text={name} />
        </div>
      </div>
      <div className="p-4 xl:p-8">
        {isShowWeightSelector && (
          <>
            {weights?.length > 1 && (
              <Typography variant="body-small-bold" className="!text-grey-480">
                Select weight
              </Typography>
            )}
            <div className="grid grid-flow-row-dense grid-cols-2 gap-2 pb-6 pt-2.5 md:pb-5 lg:pt-4 xl:pb-8">
              {weights?.map((item) => (
                <WeightSelectorButton
                  key={item?.name}
                  label={item?.label || ''}
                  size={'sm'}
                  className="flex h-12 py-2 lg:py-3.5"
                  onClick={() => handleWeightChange(item)}
                  defaultSelected={item?.name === selectedWeight}
                  isMultiSelect={false}
                />
              ))}
            </div>
          </>
        )}
        <QuantitySelector
          maxLimit={maxCartLimit}
          onChange={(value) => setSelectedQuantity(value)}
          initialCount={selectedQuantity}
          quantityContainerStyle="mb-4 md:mb-5"
          existingItemQuantity={existingItem.quantity}
        />
        <CommonButton
          button-type="primary"
          disabled={isSelectedWeightInCart && existingItem.quantity === selectedQuantity}
          onClick={handleCartClick}
          className="inline-block w-full text-center"
          color="green"
        >
          <Typography className="!text-white" variant="body-small-bold">
            {buttonLabel}
          </Typography>
        </CommonButton>
      </div>
    </div>
  );
};

export default ProductCardConfirmVertical;

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import Label from 'components/atoms/labels/text-and-icon';
import RatingReview from 'components/atoms/rating/review';
import { Typography } from 'components/atoms/typography';
import { ProductCardProps } from 'components/molecules/cards/product-card/types';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { getLinkTarget } from 'helpers/utils/common';
import { getProductCardStrainsLabelVariant } from 'helpers/utils/product-card';
import ProductCardTypography from '../product-card-typography';
import ProductCardConfirmVertical from './product-card-confirm-vertical';

const ProductCardVertical: React.FC<ProductCardProps> = ({
  showFavorite = false,
  containersStyle = '',
  image,
  name,
  productId,
  brandName,
  price,
  discountPrice,
  order,
  type,
  kind,
  thc,
  strainsLabel,
  saleLabel,
  newLabel,
  gram,
  weights,
  averageRating,
  disableReviews = false,
  isOH = false,
  existingProducts,
  totalQuantity = 0,
  reviews,
  position,
  defaultWeightSelect,
  handleSelectedWeight,
  trackViewEvent = true,
  handleSegmentEvent,
  isShowWeightSelector = true,
  buttonText = 'Add to cart',
  url = '/',
  maxCartLimit = 10,
  storeId,
}) => {
  const [showConfirmSection, setShowConfirmSection] = useState(false);
  const targetRef = useIntersectionObserver(() => {
    if (trackViewEvent && handleSegmentEvent) {
      handleSegmentEvent(SegmentEvents.PRODUCT_VIEWED);
    }
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isOH && url.includes('ohio')) {
      window.open(url, '_blank');
    } else {
      setShowConfirmSection(true);
      handleSegmentEvent && handleSegmentEvent(SegmentEvents.PRODUCT_SELECTED);
    }
  };

  const strainsLabelVariant = getProductCardStrainsLabelVariant(strainsLabel);
  useEffect(() => {
    setShowConfirmSection(false);
  }, [window?.location?.href]);

  return (
    <>
      <a
        ref={targetRef}
        href={url}
        target={getLinkTarget(url)}
        data-testid="vertical-containers"
        className={`relative flex h-[440px] w-full max-w-[260px] flex-col justify-between rounded-md border border-solid border-grey-300 bg-white md:h-[594px] md:min-w-[230px] md:max-w-[400px] xl:h-[666px] xl:min-w-[230px] xl:max-w-[432px] ${
          showConfirmSection ? '!hidden' : ''
        } ${containersStyle}`}
      >
        <div className="relative mx-auto h-[204px] w-full overflow-hidden sm:p-0 md:mx-4 md:mt-4 md:h-[320px] md:w-auto md:min-w-[195px] md:max-w-[368px] xl:max-w-[400px]">
          <Image
            layout="fill"
            alt="product card"
            loader={({ src }) => src}
            src={image}
            className="h-full object-contain lg:rounded-md"
            priority
          />
          <div className="absolute left-0 top-0 m-4 flex gap-[6px] lg:m-4 lg:gap-2">
            {showFavorite ? (
              <>
                {strainsLabel && <Label variant={strainsLabelVariant}>{strainsLabel}</Label>}
                {saleLabel && <Label variant="resin">{saleLabel}</Label>}
                {newLabel && <Label variant="grey-200">{newLabel}</Label>}
              </>
            ) : (
              strainsLabel && <Label variant={strainsLabelVariant}>{strainsLabel}</Label>
            )}
          </div>
          <div className="absolute right-0 top-0 m-4 flex gap-[6px] lg:m-3 lg:gap-2">
            {showFavorite ? (
              <IconButton button-type="primary" className="!bg-grey-200 p-2" color="white">
                {/* <MdOutlineFavorite size={'1.5em'} /> */}
              </IconButton>
            ) : (
              <>
                {saleLabel && <Label variant="resin">{saleLabel}</Label>}
                {newLabel && <Label variant="grey-200">{newLabel}</Label>}
              </>
            )}
          </div>
        </div>
        <div className="px-4 pb-4 pt-3 md:pt-2.5 xl:px-8 xl:pb-8 xl:pt-5">
          <div className="mb-3 border-b border-solid border-grey-200 pb-3 md:mb-2.5 md:pb-2.5 xl:mb-4 xl:pb-5">
            <ProductCardTypography variant="brand" text={brandName} />
            <ProductCardTypography variant="name" text={name} />
            <ProductCardTypography variant="type" text={type} />
            <ProductCardTypography variant="thc" text={thc} />
            <div className="flex flex-row items-center gap-2 pt-2">
              {averageRating > 0 && !disableReviews && (
                <RatingReview score={averageRating} text={reviews ? `${reviews} reviews` : undefined} />
              )}
            </div>
          </div>
          <div className="product-card-footer flex flex-row items-center justify-end pb-5 md:pb-2.5 xl:pb-5">
            <ProductCardTypography variant="gram" text={gram} />
            <div className="flex flex-row items-center">
              <ProductCardTypography variant="price" text={price ? Number(price).toFixed(2) : ''} className="pr-2" />
              <ProductCardTypography
                variant="discount-price"
                text={discountPrice ? Number(discountPrice).toFixed(2) : ''}
              />
            </div>
          </div>
          <CommonButton
            onClick={handleClick}
            button-type="secondary"
            color="green"
            className="inline-block w-full text-center"
          >
            <Typography variant="body-small-bold">
              {existingProducts && totalQuantity !== 0 ? (
                <div className="flex justify-center text-center">
                  Edit Quantity{' '}
                  <div className="text-arial ml-2 h-[20px] w-[20px] rounded-[9px] bg-resin py-[3px] text-xs font-bold text-white">
                    {totalQuantity}
                  </div>
                </div>
              ) : (
                buttonText
              )}
            </Typography>
          </CommonButton>
        </div>
      </a>
      {showConfirmSection && (
        <ProductCardConfirmVertical
          containersStyle={`flex ${containersStyle}`}
          name={name}
          brandName={brandName}
          image={image}
          price={price}
          discountPrice={discountPrice ? discountPrice?.toString() : null}
          strainsLabel={strainsLabel}
          onClose={() => setShowConfirmSection(false)}
          weights={weights}
          defaultWeightSelect={defaultWeightSelect}
          handleSelectedWeight={handleSelectedWeight}
          isShowWeightSelector={isShowWeightSelector}
          productId={productId}
          maxCartLimit={maxCartLimit}
          thc={thc}
          offer={saleLabel}
          order={order}
          position={position}
          kind={kind}
          storeId={storeId}
        />
      )}
    </>
  );
};

export default ProductCardVertical;

import { MENU_MIS_CONFIG_ERROR_CODE } from 'helpers/constants/error-handling';
import { WEIGHT_OBJECT } from 'helpers/constants/product';

export const capitalizeLabel = (label: string) => {
  const labelData =
    label
      ?.split('-')
      ?.map((word) => word[0]?.toUpperCase() + word?.substring(1))
      ?.join(' ') || '';
  if (label === 'cbd') {
    return label?.toUpperCase() || '';
  }
  return Boolean(label) ? labelData : null;
};

export const formatWeight = (label: string) => {
  const lowerInput = label?.toLowerCase();
  const formattedInput = lowerInput?.replace(/\s/g, '_');
  return formattedInput;
};

export const extractPrices = (priceType, prices) => {
  const result =
    prices &&
    Object?.keys(prices)
      .filter((key) => key.endsWith(`_${priceType}`))
      .reduce(
        (acc, key) => {
          const shortKey = key.replace(`_${priceType}`, '');
          return { ...acc, [shortKey]: prices[key] !== null ? prices[key] : null };
        },
        {
          price: null,
          discounted_price: null,
          special_price: null,
          max_cart_quantity: null,
        },
      );

  return result;
};
export const variantFormate = (variants) => {
  return (
    variants &&
    (Object.entries(variants || 'each') as [string, { amount?: any; product_id?: any }][])?.map(([name, data]) => {
      const variant = name !== 'null' ? name : 'each';
      return {
        label: data?.amount || capitalizeLabel(WEIGHT_OBJECT[variant] || variant),
        name: formatWeight(variant),
        value: Number(data?.product_id),
      };
    })
  );
};
// FIXME: Why are we relaying on default jane image?
export const getDefaultProductImageUrl = (type, category, root_subtype) => {
  return `https://product-assets.iheartjane.com/default-photos/${type?.replace('-', '')?.toLowerCase()}/${
    type === 'flower'
      ? category?.toLowerCase()
      : root_subtype
          ?.trim()
          ?.replace(/([^a-z0-9]+)/gi, '')
          ?.toLowerCase()
          ?.replaceAll(' ', '')
  }.png`;
};

export const getProductUrl = (menuLink: string, productId: number, urlSlug: string) => {
  if (menuLink?.includes(MENU_MIS_CONFIG_ERROR_CODE)) {
    return menuLink;
  }
  const productLink = menuLink + 'product/' + productId + '/' + urlSlug;
  return productLink?.endsWith('/') ? productLink : productLink + '/' || '';
};

import { useEffect, useState } from 'react';
import { syncCarts } from 'helpers/cart';
import { CartErrors } from 'helpers/types';
import { TProductDetails } from 'helpers/types/organisms/listing/product-details';
import { useCartHandler } from './checkout-cart';

export const useCartState = (props: TProductDetails, triggeredFrom: any, onClose?: () => void) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [selectedWeight, setSelectedWeight] = useState(props.defaultWeightSelect);
  const productWeight =
    selectedWeight !== 'undefined' && selectedWeight !== 'null' && Boolean(selectedWeight) ? selectedWeight : 'each';
  const pvid = `${props.productId}+${productWeight}`;
  const { existingItem, handleCart } = useCartHandler(props, pvid, triggeredFrom);
  const [selectedQuantity, setSelectedQuantity] = useState(existingItem?.quantity || 1);
  const isSelectedWeightInCart =
    selectedQuantity && existingItem.quantity && existingItem?.variant_id === selectedWeight;

  const handleCartClick = async () => {
    try {
      await syncCarts();
      await handleCart({
        selectedQuantity: selectedQuantity,
        setShowSnackBar: setShowSnackBar,
        onClose: onClose,
        setSelectedQuantity: setSelectedQuantity,
      });
    } catch (error) {
      CartErrors.CART_LIMIT_ERROR;
    }
  };

  useEffect(() => {
    let timer;

    if (showSnackBar) {
      timer = setTimeout(() => {
        setShowSnackBar(false);
      }, 5000);
    }

    if (existingItem?.quantity) {
      setSelectedQuantity(existingItem?.quantity);
    }

    return () => clearTimeout(timer);
  }, [showSnackBar, existingItem]);

  return {
    selectedQuantity,
    setSelectedWeight,
    selectedWeight,
    isSelectedWeightInCart,
    setSelectedQuantity,
    handleCartClick,
    existingItem,
  };
};

import tw from 'tailwind-styled-components';
import { ILabelProps } from '.';

const variantStyles: Record<ILabelProps['variant'], string> = {
  resin: 'text-white bg-resin font-bold',
  kief: 'text-leaf bg-kief font-bold',
  'grey-200': 'text-leaf bg-grey-200 font-bold',
  'white-border': 'text-grey-400 bg-white border border-solid border-grey-300 font-normal',
  white: 'text-grey-400 bg-white font-normal',
  'grey-100': 'text-grey-400 bg-grey-100 font-normal',
  'blue-border': 'text-blue-100 bg-white border border-solid border-blue-100 font-normal',
  'orange-border': 'text-orange-100 bg-white border border-solid border-orange-100 font-normal',
  'purple-border': 'text-purple-100 bg-white border border-solid border-purple-100 font-normal',
};

const defaultStyle = 'text-grey-400 bg-white font-normal';

export const LabeLContainer = tw.label<{ $variant: string }>`
  inline-flex
  items-center
  rounded-md
  font-arial
  not-italic

  px-2
  py-1.5
  text-sm
  leading-4
  tracking-[0.14px]

  md:px-[11px]
  md:py-[7px]
  md:tex-[15px]
  md:leading-[18px]
  md:tracking-[0.15px]

  xl:px-3
  xl:py-2
  xl:text-base
  xl:leading-5
  xl:tracking-[0.16px]
  ${(p) => variantStyles[p.$variant] || defaultStyle}

`;

import { IContentfulImage } from 'helpers/types/common.type';

export enum BannerStatementVariantType {
  long = 'long',
  default = 'default',
}

type TImageProps = {
  desktopImage: IContentfulImage;
  mobileImage: IContentfulImage;
};

export type TBannerStatementProps = {
  title?: string;
  overlayImage: TImageProps;
  additionalImage?: TImageProps;
  rightSideImage?: TImageProps;
  leftSideImage?: TImageProps;
  image?: TImageProps;
  ctaCollection?: {
    title: string;
    url: string;
    longUrl: string;
  };
  host?: string;
  handleSegmentEvent?: (type?: string) => void;
  cannabisQuotesCollection?: {
    items: Array<any>;
  };
  variant?: 'long' | 'default';
  faultToleranceWarn?: (title: string, identifier: string) => void;
};

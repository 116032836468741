import React from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { TSnackBarProps } from 'helpers/types/organisms/global/snack-bar';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { ButtonContainer, ContentContainer, SnackContainer, SnackContent, StyledExit } from './styles';

export const SnackBarComponent = ({
  backgroundColor,
  internalName,
  title,
  cta,
  showSnackBar,
  handleCloseAction,
  zindexPlacement,
  onClick,
  host,
}: TSnackBarProps) => {
  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: 1,
      banner_position: 1,
      banner_internal_name: internalName || '',
      banner_title: title,
      banner_type: 'Snackbar',
      link_name: cta?.title,
      link_type: 'Hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const snackBarOnClick = () => {
    if (onClick) {
      onClick();
    }
    handleSegmentEvent(SegmentEvents.BANNER_CLICKED);
  };
  return (
    showSnackBar && (
      <SnackContainer $backgroundColor={backgroundColor} $zindexPlacement={zindexPlacement}>
        <ContentContainer>
          <SnackContent $backgroundColor={backgroundColor} data-testid="snack_bar">
            {title}
          </SnackContent>
          &nbsp;
          {onClick ? (
            <CommonButton
              button-type="tertiary"
              color={backgroundColor === 'leafGreen' ? 'white' : 'green'}
              as="button"
              className="!w-auto !px-0 underline"
              onClick={snackBarOnClick}
            >
              {cta?.title}
            </CommonButton>
          ) : (
            <CommonButton
              button-type="tertiary"
              color={backgroundColor === 'leafGreen' ? 'white' : 'green'}
              as="a"
              className="!w-auto !px-0 underline"
              href={ReplaceUrlWithHost(cta?.longUrl, host)}
              onClick={snackBarOnClick}
            >
              {cta?.title}
            </CommonButton>
          )}
        </ContentContainer>

        <ButtonContainer $backgroundColor={backgroundColor}>
          <button data-close aria-label="Close Snack Bar" type="button" onClick={handleCloseAction}>
            <StyledExit aria-hidden="true" $backgroundColor={backgroundColor} />
          </button>
        </ButtonContainer>
      </SnackContainer>
    )
  );
};
